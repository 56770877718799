<template>
  <div class="onboarding__container">
    <h1 class="smalltitle onboardingtitle">
      {{ current_user.username }}, we are setting up Carl-bot in
      {{ current_guild.name }}
    </h1>
    <div class="underlined__title">
      <h4 class="smalltitle">WELCOME</h4>
    </div>
    <div>
      <b-card class="onboarding__card">
        <b-form-group>
          <h4 class="teenytinytitle">
            Welcome users to your server with a nifty welcome message. Choose a
            preset below or create your own
          </h4>
          <div class="form-group-check">
            <div class="form-group-check-inner">
              <label class="teenytinytitle">
                <input type="radio" value="none" v-model="greet_mode" />
                <svg class="checker" height="16" width="16">
                  <circle cx="8" cy="8" r="3" fill="inherit" stroke="none" />
                  <circle
                    cx="8"
                    cy="8"
                    r="6"
                    stroke="inherit"
                    strokeWidth="2"
                    fill="none"
                  />
                </svg>
                None
              </label>
            </div>
            <div class="form-group-check-inner">
              <label class="teenytinytitle">
                <input type="radio" value="preset" v-model="greet_mode" />
                <svg class="checker" height="16" width="16">
                  <circle cx="8" cy="8" r="3" fill="inherit" stroke="none" />
                  <circle
                    cx="8"
                    cy="8"
                    r="6"
                    stroke="inherit"
                    strokeWidth="2"
                    fill="none"
                  />
                </svg>
                Preset
              </label>
            </div>
            <div class="form-group-check-inner">
              <label class="teenytinytitle">
                <input type="radio" value="custom" v-model="greet_mode" />
                <svg class="checker" height="16" width="16">
                  <circle cx="8" cy="8" r="3" fill="inherit" stroke="none" />
                  <circle
                    cx="8"
                    cy="8"
                    r="6"
                    stroke="inherit"
                    strokeWidth="2"
                    fill="none"
                  />
                </svg>
                Custom
              </label>
            </div>
          </div>
          <b-input-group v-if="greet_mode === 'custom'">
            <b-form-textarea
              :maxlength="5000"
              rows="6"
              type="text"
              placeholder="Howdy {user} and welcome to {server}! Make sure to check out #rules"
              class="col-12"
              v-model="greet_message"
            ></b-form-textarea>
          </b-input-group>
          <div v-if="greet_mode === 'preset'">
            <h6 class="teenytinytitle">
              Your message will look something like
            </h6>
            Hello, {{ current_user.username }}, welcome to
            {{ current_guild.name }}. You are the 13,267th member to join.
          </div>
          <div v-if="greet_mode !== 'none'">
            <label for="discord_channel_select" class="teenytinytitle"
              >Welcome Channel</label
            >
            <multiselect
              v-model="greet_channel"
              id="discord_channel_select"
              track-by="id"
              label="name"
              placeholder="Please select a channel"
              open-direction="bottom"
              :searchable="true"
              :clear-on-select="false"
              :close-on-select="true"
              :max-height="600"
              :show-no-results="false"
              :hide-selected="false"
              :options="groupedChannels"
              :allow-empty="true"
              group-values="channels"
              group-label="category"
              :group-select="false"
            >
              <template slot="singleLabel" slot-scope="props">
                <span class="option__desc">
                  <font-awesome-icon style="color: #72767d" icon="hashtag" />
                  <span class="option__title ml-2">{{
                    props.option.name
                  }}</span>
                </span>
              </template>
              <template slot="option" slot-scope="props">
                <div class="option__desc">
                  <span class="option__title">
                    <font-awesome-icon
                      style="color: #72767d"
                      :icon="
                        !props.option['$isLabel'] ? 'hashtag' : 'chevron-down'
                      "
                    />
                    {{ props.option.name || props.option.$groupLabel.name }}
                  </span>
                </div>
              </template>
            </multiselect>
          </div>
        </b-form-group>
      </b-card>
    </div>
    <div class="onboarding__footer">
      <button class="saucy-btn" v-on:click="doUpdate">Next</button>
      <button class="saucy-btn-alt" v-on:click="goBack">Previous step</button>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { Switch as cSwitch } from "@coreui/vue";
import { Callout } from "@coreui/vue";
import VueNotifications from "vue-notifications";
import miniToastr from "mini-toastr";
import { ClientTable, Event } from "vue-tables-2";
import { createTags, VueTagsInput } from "@johmun/vue-tags-input";
import Multiselect from "vue-multiselect";

const toastTypes = {
  success: "success",
  error: "error",
  info: "info",
  warn: "warn",
};
miniToastr.init({ types: toastTypes });

function toast({ title, message, type, timeout, cb }) {
  return miniToastr[type](message, title, timeout, cb);
}

const options = {
  success: toast,
  error: toast,
  info: toast,
  warn: toast,
};

Vue.use(VueNotifications, options);

export default {
  name: "dashboard",
  components: {
    Callout,
    cSwitch,
    VueTagsInput,
    Multiselect,
  },
  data: function () {
    return {
      greet_channel: null,
      greet_message: "",
      greet_mode: "none",
      old_settings: {},
    };
  },
  notifications: {
    showChannelWarningMessage: {
      type: VueNotifications.types.error,
      title: "Missing field",
      message: "You need to select a channel when using that mode.",
    },
  },
  computed: {
    current_user() {
      return this.$store.state.discord_user || {};
    },
    current_guild() {
      return this.$store.state.discord_guilds.find(
        (x) => x.id == this.$route.params.guild_id
      );
    },

    groupedChannels() {
      const channels = this.$store.state.oauthState.channels || [];
      let groups = [
        {
          category: {
            id: null,
            name: "Uncategorized",
          },
          channels: [],
        },
      ];
      let currentGroup = groups[0];
      const sorted_channels = channels.sort((a, b) => a.position - b.position);

      for (const chn of sorted_channels) {
        if (chn.type === 4) {
          currentGroup = {
            category: { id: channel.id, name: channel.name },
            channels: [],
          };
          groups.push(currentGroup);
        }
      }
      for (let chn of sorted_channels) {
        if (chn.type === 4) {
          continue;
        }
        currentGroup = groups.find((g) => {
          return g.category.id == chn.parent_id;
        });
        if (!currentGroup) {
          currentGroup = groups[0];
        }
        currentGroup.channels.push(chn);
      }
      return groups;
    },
  },
  methods: {
    async doUpdate() {
      const guild_id = this.$route.params.guild_id;
      let greet_message;
      switch (this.greet_mode) {
        case "none":
          this.$router.push(`/onboarding/${guild_id}/reactionroles`);
          return;
        case "preset":
          greet_message =
            "Hello, {user}, welcome to {server}. You are the {ord:{server(members)}} member to join.";
          break;
        case "custom":
          greet_message = this.greet_message;
      }
      if (!this.greet_channel) {
        return this.showChannelWarningMessage();
      }

      let cfg = this.old_settings;
      cfg.greet_channel = this.greet_channel.id;
      cfg.greet_message = greet_message;

      await this.axios.put(`/api/v1/servers/${guild_id}/welcome`, {
        ban_embed: cfg.ban_embed ? JSON.parse(cfg.ban_embed) : "{}",
        ban_message: cfg.ban_message,
        dm_embed: cfg.dm_embed ? JSON.parse(cfg.dm_embed) : "{}",
        dm_message: cfg.dm_message,
        farewell_embed: cfg.farewell_embed
          ? JSON.parse(cfg.farewell_embed)
          : "{}",
        farewell_message: cfg.farewell_message,
        greet_channel: cfg.greet_channel,
        greet_embed: cfg.greet_embed ? JSON.parse(cfg.greet_embed) : "{}",
        greet_message: cfg.greet_message,
      });
      this.$router.push(`/onboarding/${guild_id}/reactionroles`);
    },
    goBack() {
      const guild_id = this.$route.params.guild_id;

      this.$router.push(`/onboarding/${guild_id}/general`);
    },
  },
  beforeRouteUpdate(to, from, next) {
    next();
    this.$store.commit("onboardingStage", 1);
    this.axios
      .get(
        "/api/v1/servers/" +
          this.$route.params.guild_id +
          "/welcome?onboarding=true"
      )
      .then((r) => {
        this.old_settings = r;
        this.greet_channel = r.greet_channel;
        this.greet_message = r.greet_message;
        if (r.greet_message !== "") {
          this.greet_mode = "custom";
        }
        this.$Progress.finish();
      });
  },
  created: function () {
    this.$Progress.start();
    this.$store.commit("onboardingStage", 1);
    this.axios
      .get(
        "/api/v1/servers/" +
          this.$route.params.guild_id +
          "/welcome?onboarding=true"
      )
      .then((r) => {
        this.old_settings = r.data;
        const channel = this.$store.state.oauthState.channels.find(
          (x) => x.id == r.data.greet_channel
        );
        if (channel) {
          this.greet_channel = channel;
        }

        this.greet_message = r.data.greet_message;
        if (this.greet_message !== "") {
          this.greet_mode = "custom";
        }
        this.$Progress.finish();
      })
      .catch((e) => {
        this.$Progress.finish();
      });
  },
};
</script>

<style src="spinkit/spinkit.min.css"></style>
<style scoped>
.prefix__container {
  display: flex;
  flex-direction: unset;
  flex-wrap: wrap;
}
.prefix__example {
  background-color: #292a33;
  padding: 3px 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 4px;
  font-weight: 500;
  border-radius: 3px;
}
</style>
<style>
.vue-tags-input {
  background: #505b64;
}

.vue-tags-input .ti-new-tag-input {
  background: transparent;
  color: #b7c4c9;
}

div.vue-tags-input {
  background: #505b64 !important;
}

.vue-tags-input .ti-input {
  padding: 4px 10px;
  transition: border-bottom 200ms ease;
  border: 1px solid #222823;
}

/* we cange the border color if the user focuses the input */
.vue-tags-input.ti-focus .ti-input {
  border: 1px solid #22222a;
}

/* some stylings for the autocomplete layer */
.vue-tags-input .ti-autocomplete {
  background: #283944;
  border-top: none;
}

/* the selected item in the autocomplete layer, should be highlighted */
.vue-tags-input .ti-item.ti-selected-item {
  background: #ebde6e;
  color: #283944;
}

/* style the placeholders color across all browser */
.vue-tags-input ::-webkit-input-placeholder {
  color: #a4b1b6;
}

.vue-tags-input ::-moz-placeholder {
  color: #a4b1b6;
}

.vue-tags-input :-ms-input-placeholder {
  color: #a4b1b6;
}

.vue-tags-input :-moz-placeholder {
  color: #a4b1b6;
}

/* default styles for all the tags */
.vue-tags-input .ti-tag {
  position: relative;
  background: #292a33;
  color: #eee;
}

/* we defined a custom css class in the data model, now we are using it to style the tag */
.vue-tags-input .ti-tag.custom-class {
  background: transparent;
  border: 1px solid #292a33;
  color: #292a33;
  margin-right: 4px;
  border-radius: 0px;
  font-size: 13px;
}

/* the styles if a tag is invalid */
.vue-tags-input .ti-tag.ti-invalid {
  background-color: #e88a74;
}

/* if the user input is invalid, the input color should be red */
.vue-tags-input .ti-new-tag-input.ti-invalid {
  color: #e88a74;
}

/* if a tag or the user input is a duplicate, it should be crossed out */
.vue-tags-input .ti-duplicate span,
.vue-tags-input .ti-new-tag-input.ti-duplicate {
  text-decoration: line-through;
}

/* if the user presses backspace, the complete tag should be crossed out, to mark it for deletion */
.vue-tags-input .ti-tag:after {
  transition: transform 0.2s;
  position: absolute;
  content: "";
  height: 2px;
  width: 108%;
  left: -4%;
  top: calc(50% - 1px);
  background-color: #000;
  transform: scaleX(0);
}

.vue-tags-input .ti-deletion-mark:after {
  transform: scaleX(1);
}

.onboarding__container {
  display: flex;
  align-items: center;
  background-color: #22222a;
  flex-direction: column;
  flex-grow: 1;
  flex-wrap: wrap;
}

.underlined__title {
  border-bottom: 4px solid #49d6df;
  padding-left: 10px;
  padding-right: 10px;
  display: inline-flex;
  align-self: center;
}
</style>